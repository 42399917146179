import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './Tienda.css';
import '../Detail/Detail.css';
import 'swiper/swiper-bundle.css';
import 'react-toastify/dist/ReactToastify.css';
import TiendaLoading from '../TiendaLoading/TiendaLoading';
import { Link as Anchor, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import Products from '../Products/Products';
import Modal from 'react-responsive-modal';
import instagram from '../instagram';

export default function Tienda() {
    const [loading, setLoading] = useState(true);
    const [tienda, setTienda] = useState(null);
    const { idTienda } = useParams();
    const [categorias, setCategorias] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');

    useEffect(() => {
        cargarTiendas();
        cargarCategoria();
    }, [idTienda]);

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tienda = data.tiendas.find(t => t.idTienda === parseInt(idTienda));
                setTienda(tienda);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
                console.log(data.categorias);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const handleCompartirClick = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Echa un vistazo a este producto',
                url: window.location.href,
            })
                .then(() => console.log('Contenido compartido correctamente'))
                .catch((error) => console.error('Error al compartir:', error));
        } else {
            console.error('La API de compartir no está disponible en este navegador.');
        }
    };

    const handleImageClick = (image) => {
        setModalImage(image);
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setModalImage('');
    };

    return (
        <div className='demo'>
            {loading ? (
                <TiendaLoading />
            ) : tienda ? (
                <div className='TiendaContainDetail'>
                    <div className='bannerTienda' onClick={() => handleImageClick(tienda.imagen2)}>
                        <img src={tienda.imagen2} alt="imagen" className='imgTiendaBanner' />
                    </div>
                    <div className='cardTextTiendaDetail'>
                        <div className='deFlexCardTienda'>
                            <div className='deColumnCardTienda' onClick={() => handleImageClick(tienda.imagen1)}>
                                <img src={tienda.imagen1} alt="imagen" className='CardlogoTienda' />
                                <hr />
                            </div>
                            <div className='cardTextTiendaDetailText'>
                                <div className='deFlexCardTextTiendaDetailText'>
                                    <h2>{tienda.nombre}</h2>
                                    {categorias
                                        .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === tienda.idCategoria)
                                        .map(categoriaFiltrada => (
                                            <h3 key={categoriaFiltrada.idCategoria}>{categoriaFiltrada.categoria}</h3>
                                        ))
                                    }
                                </div>
                                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(tienda.direccion)}`} target="_blank">{tienda.direccion}</Anchor>
                                <div className='socials'>
                                    <Anchor to={tienda.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                                    <Anchor to={tienda.instagram} target="_blank">  <FontAwesomeIcon icon={faTiktok} /> </Anchor>
                                    <Anchor to={instagram} target="_blank"> <i className='fa fa-instagram'></i> </Anchor>
                                    <Anchor to={`tel:${tienda.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Products />
                    <Modal open={open} onClose={closeModal} center>
                        <img src={modalImage} alt="Imagen modal" style={{ width: '100%' }} />
                    </Modal>
                </div>
            ) : (
                <div>No se encontró la tienda</div>
            )
            }
        </div >
    );
}
