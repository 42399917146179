import React, { useState, useEffect } from 'react';
import { Link as Anchor } from 'react-router-dom';
import LoginUsuario from '../LoginUsuario/LoginUsuario';
import RegisterUsuario from '../RegisterUsuario/RegisterUsuario';
import './ModalAuth.css';
import user from '../../images/user.png';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InfoUserMain from '../Admin/InfoUserMain/InfoUserMain';
import baseURL from '../url';
import Spiner from '../Admin/Spiner/Spiner';
import Logout from '../Admin/Logout/Logout';

export default function ModalAuth() {
    const [modalContent, setModalContent] = useState('login');
    const [isOpen, setIsOpen] = useState(false);
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Comprobar si 'close' está en sessionStorage
        const isModalClosed = sessionStorage.getItem('close');

        // Si no está en sessionStorage, abre el modal después de 2 segundos
        if (!isModalClosed) {
            const timer = setTimeout(() => {
                setIsOpen(true);
            }, 2000);

            // Limpiar el temporizador si el componente se desmonta
            return () => clearTimeout(timer);
        }
    }, []);

    const handleCloseModal = () => {
        // Guardar 'close' en sessionStorage para evitar que se abra en el futuro
        sessionStorage.setItem('close', 'true');
        setIsOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='AuthContainerClient'>
            {loading ? (
                <Spiner />
            ) : usuario.idUsuario ? (
                <>

                </>
            ) : (
                <>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={handleCloseModal}
                        className="modalAuthHome"
                        overlayClassName="overlayAuthHome"
                    >
                        <div className="modalAuth-content">
                            <div className='deFlexModal'>
                                <span onClick={handleCloseModal} className="closeModal">
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </span>
                                <h3>Mi cuenta</h3>
                            </div>
                            <Anchor to={`/`}>
                                <img src={user} alt="imagen" className='logoAtuh' />
                            </Anchor>
                            <div className='authButtons'>
                                <button
                                    onClick={() => setModalContent('login')}
                                    className={`authButtonModal ${modalContent === 'login' ? 'selected' : ''}`}
                                >
                                    Ingresar
                                </button>
                                <button
                                    onClick={() => setModalContent('register')}
                                    className={`authButtonModal ${modalContent === 'register' ? 'selected' : ''}`}
                                >
                                    Registrarse
                                </button>
                            </div>
                            {modalContent === 'login' ? <LoginUsuario /> : <RegisterUsuario />}
                        </div>
                    </Modal>
                </>
            )}

        </div>
    );
}
