import React, { useState, useEffect } from "react";
import "./InputSearchs.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import baseURL from '../url';

export default function InputSearchs() {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducto, setFilteredProducto] = useState([]);
    const [filteredTiendas, setFilteredTiendas] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tiendas, setTiendas] = useState([]);
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriasProductos, setCategoriasProductos] = useState([]);
    useEffect(() => {
        cargarProductos();
        cargarCategoriaProductos();
        cargarCategoriaTienda();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const productosTienda = data.tiendas.flatMap(tienda => tienda?.productos || []);
                setTiendas(data.tiendas || []);
                setProductos(productosTienda);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };
    const cargarCategoriaTienda = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const cargarCategoriaProductos = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategoriasProductos(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        // Filtrar productos por título o por categoría
        const filteredProductos = productos.filter(producto => {
            const matchesTitle = producto.titulo.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = categoriasProductos.some(categoria =>
                categoria.idCategoria === producto.idCategoria &&
                categoria.categoria.toLowerCase().includes(searchTerm.toLowerCase())
            );
            return matchesTitle || matchesCategory;
        });

        // Filtrar tiendas por nombre o por categoría
        const filteredTiendas = tiendas.filter(tienda => {
            const matchesName = tienda.nombre.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = categorias.some(categoria =>
                categoria.idCategoria === tienda.idCategoria &&
                categoria.categoria.toLowerCase().includes(searchTerm.toLowerCase())
            );
            return matchesName || matchesCategory;
        });

        setFilteredProducto(filteredProductos);
        setFilteredTiendas(filteredTiendas);
        setShowResults(searchTerm !== "");
        setNoResults(searchTerm !== "" && filteredProductos.length === 0 && filteredTiendas.length === 0);
    };


    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleLinkClick = (url) => {
        closeModal();
        window.location.href = url;
    };

    return (
        <div className="fondo-input">
            <div className="search-container">
                <div className="inputSerch" onClick={openModal}>
                    <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={openModal} />
                    <strong>Buscar</strong>
                </div>
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modalInput" overlayClassName="overlayInput">
                    <fieldset className="inputSearch">
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="input"
                        />
                    </fieldset>
                    {showResults && (
                        <div className="modalSearch">
                            {filteredTiendas.length > 0 && (
                                <div className="sectionSearch">
                                    <h3>Tiendas</h3>
                                    <hr />
                                    {filteredTiendas.map(tienda => (
                                        <div key={tienda.idTienda}>
                                            <a href={`/tienda/${tienda.idTienda}/${tienda.nombre.replace(/\s+/g, '-')}`} onClick={() => handleLinkClick(`/tienda/${tienda.idTienda}/${tienda.nombre.replace(/\s+/g, '-')}`)}>
                                                <img src={tienda.imagen1} alt="" />
                                                <div>
                                                    <h4>{tienda.nombre}</h4>
                                                    {
                                                        categorias
                                                            .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === tienda.idCategoria)
                                                            .map(categoriaFiltrada => (
                                                                <h6 key={categoriaFiltrada.idCategoria}> {categoriaFiltrada.categoria}</h6>
                                                            ))
                                                    }
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {filteredProducto.length > 0 && (
                                <div className="sectionSearch">
                                    <h3>Productos</h3>
                                    <hr />
                                    {filteredProducto.map(item => (
                                        <div key={item.idProducto}>
                                            <Link to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`} onClick={closeModal}>
                                                <img src={item.imagen1} alt="" />

                                                <div>
                                                    <h4>{item.titulo}</h4>
                                                    {
                                                        tiendas?.filter(filt => filt.idTienda === item.idTienda).map(mape => (
                                                            <>
                                                                {mape.nombre}
                                                            </>
                                                        ))
                                                    }

                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {noResults && <p>No se encontraron resultados.</p>}
                        </div>
                    )}
                </Modal>
            </div>
        </div>
    );
}
