import React, { useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import { Link as Anchor, useParams } from 'react-router-dom';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import instagram from '../instagram';
export default function ProfileTienda() {
    const [tienda, setTienda] = useState(null);
    const { idTienda } = useParams();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        cargarTiendas();
    }, [idTienda]);

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tiendaEncontrada = data?.tiendas?.find(t => t.idTienda === parseInt(idTienda));
                setTienda(tiendaEncontrada);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    return (
        <div className='profileContain'>
            {loading ? (
                <div className='loadingBannerFondo'>

                </div>

            ) : (

                <>
                    <div className='fondo'>
                        <img src={tienda?.imagen2} alt="logo" />
                    </div>
                    <div className='profileText'>
                        <img src={tienda?.imagen1} alt="logo" />
                        <h2>{tienda?.nombre}</h2>
                        <div className='socials'>
                            {tienda?.facebook && (
                                <Anchor to={tienda.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                            )}
                            {tienda?.instagram && (
                                <Anchor to={tienda.instagram} target="_blank"> <FontAwesomeIcon icon={faTiktok} /></Anchor>
                            )}
                            <Anchor to={instagram} target="_blank"> <i className='fa fa-instagram'></i> </Anchor>
                            {tienda?.telefono && (
                                <Anchor to={`tel:${tienda.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                            )}
                        </div>

                        {tienda?.email && (
                            <Anchor to={`mailto:${tienda.email}`} target="_blank">{tienda.email}</Anchor>
                        )}
                        {tienda?.direccion && (
                            <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(tienda.direccion)}`} target="_blank">{tienda.direccion}</Anchor>
                        )}

                    </div>
                </>
            )}

        </div>
    );
}
