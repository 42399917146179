import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './LinksNav.css';
import { useParams, Link as Anchor } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

// Establecer el elemento para el modal
Modal.setAppElement('#root');

export default function LinksNav() {
    const [tienda, setTienda] = useState(null);
    const { idTienda } = useParams();
    const [categorias, setCategorias] = useState([]);
    const [tiendas, setTiendas] = useState([]);
    const [productos, setProductos] = useState([]);
    const [categoriasProductos, setCategoriasProductos] = useState([]);
    const [showModal, setShowModal] = useState(false); // Estado para mostrar el modal
    const [modalView, setModalView] = useState('tiendas'); // Estado para la vista activa en el modal
    const toast = React.useRef(null);

    useEffect(() => {
        cargarTiendas();
        cargarCategoriaProductos();
        cargarCategoriaTienda();
    }, [idTienda]);

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTiendas(data.tiendas || []);
                const tienda = data.tiendas.find(t => t.idTienda === parseInt(idTienda));
                setTienda(tienda);
                setProductos(data.tiendas.flatMap(tienda => tienda?.productos) || []);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    const cargarCategoriaTienda = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const cargarCategoriaProductos = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategoriasProductos(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    const tiendasPorCategoria = categorias
        .map(categoria => ({
            ...categoria,
            tiendas: tiendas.filter(tienda => tienda.idCategoria === categoria.idCategoria)
        }))
        .filter(categoria => categoria.tiendas.length > 0);

    const productosPorCategoria = categoriasProductos
        .map(categoria => ({
            ...categoria,
            productos: productos.filter(producto => producto.idCategoria === categoria.idCategoria)
        }))
        .filter(categoria => categoria.productos.length > 0);

    return (
        <div className="containerLinks">
            <Toast ref={toast}></Toast>

            <div className="menu-item">
                Tiendas
                <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '10px' }} />
                <div className="submenu">
                    {tiendasPorCategoria.map(categoria => (
                        <div key={categoria.idCategoria} className="categoria-container">
                            <h6>{categoria.categoria}</h6>
                            <hr />
                            {categoria.tiendas.map(tienda => (
                                <a key={tienda.idTienda} href={`/tienda/${tienda.idTienda}/${tienda.nombre.replace(/\s+/g, '-')}`}>
                                    <img src={obtenerImagen(tienda)} alt="" />
                                    {tienda.nombre}
                                </a>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            <div className="menu-item" style={{ marginTop: '20px' }}>
                Productos
                <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '10px' }} />
                <div className="submenu">
                    {productosPorCategoria.map(categoria => (
                        <div key={categoria.idCategoria} className="categoria-container">
                            <h6>{categoria.categoria}</h6>
                            <hr />
                            {categoria.productos.map(producto => (
                                <Anchor key={producto.idProducto} to={`/producto/${producto.idProducto}/${producto.titulo.replace(/\s+/g, '-')}`}>
                                    <img src={obtenerImagen(producto)} alt="imagen" />
                                    <div className='deColumnTe'>
                                        <span> {producto.titulo}</span>
                                        <span> {
                                            tiendas?.filter(filt => filt.idTienda === producto?.idTienda)?.map(mape => (
                                                <>
                                                    {mape.nombre}
                                                </>
                                            ))
                                        }</span>
                                    </div>
                                </Anchor>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            {/* Icono + para pantallas pequeñas */}
            <button
                className="toggle-button"
                onClick={() => setShowModal(true)} // Abrir el modal
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>

            {/* Modal para pantallas pequeñas */}
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)} // Cerrar el modal al hacer clic fuera del contenido
                className="modalLinks"
                overlayClassName="overlayInput"
                contentLabel="Menu Modal"
            >


                <div>
                    <div className='deFLex'>
                        <button onClick={() => setShowModal(false)} >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                    </div>
                    <div className="modal-flexButons">
                        <button
                            className={`modal-button ${modalView === 'tiendas' ? 'active' : ''}`}
                            onClick={() => setModalView('tiendas')}
                        >
                            Tiendas
                        </button>
                        <button
                            className={`modal-button ${modalView === 'productos' ? 'active' : ''}`}
                            onClick={() => setModalView('productos')}
                        >
                            Productos
                        </button>
                    </div>
                    {modalView === 'tiendas' ? (
                        <div className="submenu" >
                            {tiendasPorCategoria.length > 0 ? (
                                tiendasPorCategoria.map(categoria => (
                                    <div key={categoria.idCategoria} className="categoria-container">
                                        <h6>{categoria.categoria}</h6>
                                        <hr />
                                        {categoria.tiendas.map(tienda => (
                                            <a key={tienda.idTienda} href={`/tienda/${tienda.idTienda}/${tienda.nombre.replace(/\s+/g, '-')}`}>
                                                <img src={obtenerImagen(tienda)} alt="" />
                                                {tienda.nombre}
                                            </a>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <p>No hay tiendas disponibles.</p>
                            )}
                        </div>

                    ) : (

                        <div className="submenu">
                            {productosPorCategoria.length > 0 ? (
                                productosPorCategoria.map(categoria => (
                                    <div key={categoria.idCategoria} className="categoria-container">
                                        <h6>{categoria.categoria}</h6>
                                        <hr />
                                        {categoria.productos.map(producto => (
                                            <Anchor key={producto.idProducto} to={`/producto/${producto.idProducto}/${producto.titulo.replace(/\s+/g, '-')}`}>
                                                <img src={obtenerImagen(producto)} alt="imagen" />
                                                <div className='deColumnTe'>
                                                    <span> {producto.titulo}</span>
                                                    <span> {
                                                        tiendas?.filter(filt => filt.idTienda === producto.idTienda)?.map(mape => (
                                                            <>
                                                                {mape.nombre}
                                                            </>
                                                        ))
                                                    }</span>
                                                </div>
                                            </Anchor>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <p>No hay productos disponibles.</p>
                            )}
                        </div>

                    )}
                </div>
            </Modal>
        </div>
    );
}
