import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './Products.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import { Link as Anchor, useParams } from "react-router-dom";
import moneda from '../moneda';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Products() {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productos, setProductos] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Todo');
    const [tiendas, setTiendas] = useState([]);
    const categoriasInputRef = useRef(null);
    const categoriasRefs = useRef([]);

    const { idTienda } = useParams();

    useEffect(() => {
        cargarProductos();
        cargarCategoria();
    }, [idTienda]);  // Se actualiza cada vez que idTienda cambia

    useEffect(() => {
        if (productos.length > 0) {
            const categoriaIds = productos.map(producto => producto.idCategoria);
            setCategorias(categorias.filter(categoria => categoriaIds.includes(categoria.idCategoria)));
        }
    }, [productos]);

    const cargarProductos = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tiendasData = data.tiendas;
                setTiendas(tiendasData)
                const productosTienda = data.tiendas
                    .flatMap(tienda => tienda?.productos || [])
                    .filter(producto => producto.idTienda === parseInt(idTienda));

                setProductos(productosTienda);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    const handleClickCategoria = (categoria) => {
        setCategoriaSeleccionada(categoria);
    };

    return (
        <div className='ProductsContain'>
            <ToastContainer />
            {productos?.length > 0 && (
                <div className='categoriasInputs' ref={categoriasInputRef}>
                    <input
                        type="button"
                        value="Todo"
                        onClick={() => handleClickCategoria('Todo')}
                        style={{
                            backgroundColor: categoriaSeleccionada === 'Todo' ? '#402600' : '',
                            color: categoriaSeleccionada === 'Todo' ? '#fff' : '',
                            borderBottom: categoriaSeleccionada === 'Todo' ? '2px solid #402600' : 'none'
                        }}
                    />
                    {categorias.map(({ categoria, idCategoria }) => (
                        <input
                            key={idCategoria}
                            type="button"
                            value={categoria}
                            onClick={() => handleClickCategoria(idCategoria)}
                            style={{
                                backgroundColor: categoriaSeleccionada === idCategoria ? '#402600' : '',
                                color: categoriaSeleccionada === idCategoria ? '#fff' : '',
                                borderBottom: categoriaSeleccionada === idCategoria ? '2px solid #402600' : 'none'
                            }}
                        />
                    ))}
                </div>
            )}

            {loading ? (
                <ProductosLoading />
            ) : (
                <div className='Products'>
                    {categoriaSeleccionada === 'Todo' && (
                        <>
                            {productos.some(item => item.masVendido === "si") && (
                                <div className='categoriSection'>
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        slidesPerView={'auto'}
                                        id='swiper_container_products'
                                        autoplay={{ delay: 3000 }}
                                    >
                                        {productos.filter(item => item.masVendido === "si").map(item => (
                                            <SwiperSlide key={item.idProducto} id='SwiperSlide-scroll-products-masvendidos'>
                                                <Anchor className='cardProdcutmasVendido' to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                    <img src={obtenerImagen(item)} alt="imagen" />
                                                    <h6 className='masVendido'>Más Vendido</h6>
                                                    <div className='cardText'>
                                                        <h4>{item.titulo}</h4>
                                                        <span>{item.descripcion}</span>
                                                        <div className='deFLexPrice'>
                                                            <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                                <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Anchor>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}

                            {productos?.length > 0 && (
                                <>
                                    {categorias.map(({ categoria, idCategoria }) => (
                                        <div key={idCategoria} className='categoriSection' ref={ref => categoriasRefs.current[categorias.findIndex(cat => cat.categoria === idCategoria)] = ref}>
                                            <div className='deFlexTitlesection'>
                                                <h3>{categoria}</h3>
                                                <button onClick={() => handleClickCategoria(idCategoria)}>
                                                    Ver más
                                                </button>

                                            </div>
                                            <div className='categoriSectionSelected'>
                                                {productos.filter(item => item.idCategoria === idCategoria).map(item => (
                                                    <Anchor key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                        <div className='cardProdcutSelected'>
                                                            <img src={obtenerImagen(item)} alt="imagen" />
                                                            <div className='cardTextSelected'>
                                                                <h4>{item.titulo}</h4>
                                                                <span>{item.descripcion}</span>
                                                                <div className='deFLexPrice'>
                                                                    <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                                    {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                                        <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                                    )}
                                                                </div>
                                                                <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                                            </div>
                                                        </div>
                                                    </Anchor>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}


                        </>
                    )}


                    <div className='categoriSectionSelected'>
                        {productos
                            .filter(item => categoriaSeleccionada !== 'Todo' && item.idCategoria === categoriaSeleccionada)
                            .map(item => (
                                <Anchor key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                    <div className='cardProdcutSelected'>
                                        <img src={obtenerImagen(item)} alt="imagen" />
                                        <div className='cardTextSelected'>
                                            <h4>{item.titulo}</h4>
                                            <span>{item.descripcion}</span>
                                            <div className='deFLexPrice'>
                                                <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                    <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                )}
                                            </div>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                        </div>
                                    </div>
                                </Anchor>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
}
