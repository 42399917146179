import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import LoadingProductos from '../LoadingProductos/LoadingProductos';
import { Link as Anchor, useParams } from "react-router-dom";
import moneda from '../moneda';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function ProductsHome() {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fixedCategories, setFixedCategories] = useState(false);
    const [productos, setProductos] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Más vendido');
    const [tiendas, setTiendas] = useState([]);
    const categoriasInputRef = useRef(null);


    useEffect(() => {
        cargarProductos();
        cargarCategoria();
    }, []);

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const cargarProductos = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tiendasData = data.tiendas;
                setTiendas(tiendasData);
                const productos = tiendasData.flatMap(tienda => tienda?.productos) || [];
                const productosAleatorios = shuffleArray(productos).slice(0, 15);
                setProductos(productosAleatorios);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };


    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    const handleClickCategoria = (categoria) => {
        setCategoriaSeleccionada(categoria);
    };

    return (
        <div >

            {productos?.length > 0 && (
                <div className={`categoriasInputs ${fixedCategories ? 'fixed' : ''}`} ref={categoriasInputRef}>
                    <input
                        type="button"
                        value="Más vendido"
                        onClick={() => handleClickCategoria('Más vendido')}
                        style={{
                            backgroundColor: categoriaSeleccionada === 'Más vendido' ? '#402600' : '',
                            color: categoriaSeleccionada === 'Más vendido' ? '#fff' : '',
                            borderBottom: categoriaSeleccionada === 'Más vendido' ? '2px solid #402600' : 'none'
                        }}
                    />
                    {categorias
                        .filter(categoria => productos.some(producto => producto.idCategoria === categoria.idCategoria))
                        .map(({ categoria, idCategoria }) => (
                            <input
                                key={idCategoria}
                                type="button"
                                value={categoria}
                                onClick={() => handleClickCategoria(idCategoria)}
                                style={{
                                    backgroundColor: categoriaSeleccionada === idCategoria ? '#402600' : '',
                                    color: categoriaSeleccionada === idCategoria ? '#fff' : '',
                                    borderBottom: categoriaSeleccionada === idCategoria ? '2px solid #402600' : 'none'
                                }}
                            />
                        ))}
                </div>
            )}

            {loading ? (
                <LoadingProductos />
            ) : (
                <div className='Products'>
                    {categoriaSeleccionada === 'Más vendido' && (
                        <>
                            {productos.some(item => item.masVendido === "si") && (
                                <div className='categoriSection'>
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        slidesPerView={'auto'}
                                        id='swiper_container_products'
                                        autoplay={{ delay: 3000 }}
                                    >
                                        {productos.filter(item => item.masVendido === "si").map(item => (
                                            <SwiperSlide key={item.idProducto} id='SwiperSlide-scroll-products-masvendidos'>
                                                <Anchor className='cardProdcutmasVendido' to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                    <img src={obtenerImagen(item)} alt="imagen" />
                                                    <h6 className='masVendido'>Más Vendido</h6>
                                                    <div className='cardText'>
                                                        <h4>{item.titulo}</h4>
                                                        <div className='deFLexPrice'>
                                                            <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                                <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            )}
                                                        </div>
                                                        {
                                                            tiendas?.filter(tiendaFiltrada => tiendaFiltrada.idTienda === item.idTienda).map(tienda => (
                                                                <div className='deFLexlogo'>
                                                                    <img src={tienda?.imagen1} alt="logo" className='logoTiendaProducto' />
                                                                    <h5> {tienda?.nombre}</h5>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </Anchor>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                        </>
                    )}

                    <div className='categoriSectionSelected'>
                        {productos
                            .filter(item => categoriaSeleccionada !== 'Más vendido' && item.idCategoria === categoriaSeleccionada)
                            .map(item => (
                                <Anchor key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                    <div className='cardProdcutSelected'>
                                        <img src={obtenerImagen(item)} alt="imagen" />
                                        <div className='cardTextSelected'>
                                            <h4>{item.titulo}</h4>
                                            <div className='deFLexPrice'>
                                                <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                {(item.precioAnterior !== 0 && item.precioAnterior !== undefined) && (
                                                    <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                )}
                                            </div>
                                            {
                                                tiendas?.filter(tiendaFiltrada => tiendaFiltrada.idTienda === item.idTienda).map(tienda => (
                                                    <div className='deFLexlogo'>
                                                        <img src={tienda?.imagen1} alt="logo" className='logoTiendaProducto' />
                                                        <h5> {tienda?.nombre}</h5>
                                                    </div>
                                                ))
                                            }
                                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                        </div>
                                    </div>
                                </Anchor>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
}
