import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link as Anchor, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logo.png';
import baseURL from '../url';
import 'swiper/swiper-bundle.css';
import Profile from '../Profile/Profile';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Favoritos from '../Favoritos/Favoritos';
import InputSerach from '../InputSerach/InputSearchs';
import ProfileTienda from '../ProfileTienda/ProfileTienda';
import LinksNav from '../LinksNav/LinksNav';
import AuthUsuario from '../AuthUsuario/AuthUsuario'
export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        if (location.key !== 'default') {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const isHomePage = location.pathname === '/';
    return (
        <header>
            <nav>
                {isHomePage ? (
                    <Anchor to={`/`} className='logo'>
                        <img src={logo} alt="logo" />
                    </Anchor>
                ) : (
                    <button className="back" onClick={goBack}> <FontAwesomeIcon icon={faArrowLeft} /> </button>
                )}



                <div className='deFLexNavs'>
                    <LinksNav />
                    <AuthUsuario />
                    <Favoritos />
                    <InputSerach />
                    <div className={`nav_toggle ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        {isHomePage ? (

                            <Profile />

                        ) : (
                            <ProfileTienda />
                        )}

                    </div>
                </Modal>
            </nav>
        </header>
    );
}
